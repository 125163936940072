<main (window:resize)="onResized()"> <div #main style="overflow: hidden;" class="grid-screen-three"> <div style="height: 100%;" class="row" #screen1> <section class="screen-item" #sectionLayout style="height: 100%; width: 100%;"> <div class="screen-item-min" style="width: 100%;"> <div class="task-item" [ngStyle]="{ background: colorTask ? colorTask : '#000' }"> <div class="container-fluid"> <div class="dropdown list-category list-item"> <div class="set-category" id="dropdownMenuButton" data-toggle="dropdown" (click)="chooseProgram(screen1, menuSoftware1)"> <em class="btn" type="button"> <svg xmlns="http://www.w3.org/2000/svg" width="13.609" height="11.49" viewBox="0 0 13.609 11.49"> <g id="Group_684" data-name="Group 684" transform="translate(-411.934 -428.626)"> <g id="Group_683" data-name="Group 683"> <line id="Line_422" data-name="Line
                                            422" x2="11.531" transform="translate(412.973
                                            429.665)" fill="none" stroke="#a1cdff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.077"/> <line id="Line_423" data-name="Line
                                            423" x2="11.531" transform="translate(412.973
                                            434.372)" fill="none" stroke="#a1cdff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.077"/> <line id="Line_424" data-name="Line
                                            424" x2="11.531" transform="translate(412.973
                                            439.078)" fill="none" stroke="#a1cdff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.077"/> </g> </g> </svg> </em> <label>{{ translateService.instant('common.software.' + moduleName) }}</label> <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" #menuSoftware1> <li *ngFor="let software of ALL_SOFTWARES"> <a *checkPrivileges="software" [ngClass]="
                          (projectId && MODULE_NAME_NEED_PROJECT.includes(software)) || !MODULE_NAME_NEED_PROJECT.includes(software)
                            ? ''
                            : 'disabled'
                        " (click)="changeComponent(MODULE_NAME[software], NUMBER_LAYOUT.LAYOUT_1)"><span class="ic-color" [style.background]="COLOR_TITLE[MODULE_NAME[software]]"></span><em>{{ translateService.instant('common.software.' + software) }}</em></a> </li> </ul> </div> </div> <section> <ng-container #moduleLayout_1></ng-container> </section> <a href="javascript:void(0)" class="panel-fullscreen pull-right" [hidden]="colorTask == '#000' || colorTask == undefined || userId != Constant.ROOT"> <svg xmlns="http://www.w3.org/2000/svg" width="18.748" height="12.899" viewBox="0 0 18.748 12.899"> <g id="Group_694" data-name="Group 694" transform="translate(-3774.036 -423.689)" opacity="0.5"> <g id="Group_693" data-name="Group 693"> <path id="Path_869" data-name="Path 869" d="M3790.784,425.689v8.9h-14.748v-8.9h14.748m2-2h-18.748v12.9h18.748v-12.9Z" fill="#282828"/> <path id="Path_870" data-name="Path 870" d="M3789.784,430.689l-4-4h4Z" fill="#282828"/> <path id="Path_871" data-name="Path 871" d="M3777.036,429.589l4,4h-4Z" fill="#282828"/> </g> </g> </svg> </a> </div> </div> <section class="main-content" [ngClass]="colorTask == '#000' ? 'no-data' : ''"> <ng-container #moduleLayout></ng-container> </section> </div> </section> </div> </div> </main> 